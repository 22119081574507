import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Menu from "../components/menu_compuesto"

import '../sass/pages/cliente.scss'
import '../sass/app/menu_version_2.scss'

const Proveedor = () => (
  <Layout>
		<Seo title="Soy Proveedor" />
        <Menu />

        <div className="cliente">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 offset-lg-1 col-xl-3 order-md-2 mb-3 mb-md-0">
                        <StaticImage src="../images/phone-proveedor.png" quality={100} formats={["AUTO", "WEBP", "AVIF"]} alt="Soy cliente"></StaticImage>
                    </div>
                    <div className="col-12 col-md-6 order-md-1">
                        <h3 className="titulo mb-5">Soy Proveedor</h3>

                        <p>Ofrece tus servicios a las personas en la comunidad que necesitan de ti.</p>
                        <p>Semple te enlaza con la gente que necesita de tus servicios de forma directa.</p>
                        <p>Recibe ofertas de trabajos en tiempo real y ofrécele al cliente tu mejor propuesta de forma simple, rápida y sin cobro de comisiones. Tu negocio. Tus precios. Nosotros sólo te enlazamos.</p>
                        <p className="mb-5">Semple es la nueva App en la que encuentras más trabajo.</p>

                        <a href="https://play.google.com/store/apps/details?id=mx.semple.android.provider">
                            <StaticImage src="../images/googleplay.jpg" quality={100} alt="Google Play" style={{marginRight: "10px"}}></StaticImage>
                        </a>
                        <a href="https://apps.apple.com/us/app/semple-proveedor/id1445784921">
                            <StaticImage src="../images/appstore.jpg" quality={100} formats={["png"]} alt="App Store"></StaticImage>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-gris">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <h3 className="titulo">Beneficios de ser Proveedor Semple</h3>
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1">
                        <p className="mb-5">Semple te ayuda a conseguir nuevos clientes y elevar tus ventas, enlazándote en tiempo real con personas que necesiten de algún servicio de tu ramo.  No te cobramos comisión por los servicios que ofrezcas.  Es tu negocio y son tus precios. Nosotros sólo los enlazamos.</p>

                        <ul className="ps-3">
                            <li className="mb-3">Descarga la app Semple Proveedores</li>
                            <li className="mb-3">Asegúrate de mantener tu sesión iniciada</li>
                            <li className="mb-3">Recibe en solicitudes de trabajos en tiempo real</li>
                            <li className="mb-3">Tú eliges aplicar si el trabajo te interesa</li>
                            <li className="mb-3">Ofrécele al cliente tu propuesta.</li>
                            <li className="mb-3">Si eres seleccionado por el cliente, él te contactará.</li>
                            <li>Puedes aplicar a todos los trabajos que decidas sin costo adicional.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

  </Layout>
)

export default Proveedor
