import * as React from "react"
import { Link } from "gatsby"

import logo from '../images/logo.png'

const activateSidebar = function () {
    document.querySelector("#btn-menu").classList.toggle("active");
    document.querySelector(".sidebar").classList.toggle("d-none");
}

const Header2 = () => (
  <header className="menu-version-2 text-center">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-6 col-md-4 text-start">
                    <Link to="/" >
                        <img src={logo} alt="Semple" className="logo"></img>
                    </Link>
                </div>
                <div className="col-md-8 text-end d-none d-md-block">
                    <ul className="list-unstyled d-flex align-items-center justify-content-end">
                        <li className="me-5">
                            <Link to="/cliente" activeClassName="active">Soy Cliente</Link>
                        </li>
                        <li className="me-5  ">
                            <Link to="/proveedor" activeClassName="active">Soy Proveedor</Link>
                        </li>
                        <li>
                            <a href="mailto:info@semple.app">Contacto</a>
                        </li>
                    </ul>
                </div>
                <div className="col-6 d-md-none text-end">
                    <div className="menu menu-3 d-inline-block" id="btn-menu" onClick={activateSidebar}>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>

        <div className="sidebar d-none d-md-none pt-3 pb-3">
            <div className="container">
                <ul className="list-unstyled text-end">
                    <li className="mb-3">
                        <Link to="/cliente" activeClassName="active">Soy Cliente</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/proveedor" activeClassName="active">Soy Proveedor</Link>
                    </li>
                    <li>
                        <a href="mailto:info@semple.app">Contacto</a>
                    </li>
                </ul>
            </div>
        </div>
  </header>
)

export default Header2
